<template>
    <legend v-show="showTitle"><span v-html="Title"></span></legend>

    <div class="text-end">
        <span class="text-danger font-weight-bold" v-show="isChanged">* Unsaved Changes *</span>
        <neu-button type="button" fill="raised" id="reset_btn" @click="formOnReset" v-show="isChanged">
            <font-awesome-icon :icon="['fas', 'redo']" size="sm" class="mr-2" /> Reset
        </neu-button>
    </div>

    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Facility</label>
                        <select class="neu-select" name="facility" data-role="dropdownlist"
                            data-option-label="Select a Facility"
                            :class="[isMoveDisabled ? 'readOnly' : '', isFacilityRequired ? 'hasError' : '']"
                            v-model="FacilityId" :disabled="isMoveDisabled" @change="facilitiesOnChange">
                            <option v-for="fac in facilities" :value="fac.value" :key="fac.value">
                                {{ fac.text }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isFacilityRequired" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isFacilityRequired" class="requiredTxt">Required</span>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Service Line</label>
                        <neu-input name="clusterName" readonly disabled v-model="ClusterName"></neu-input>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Department</label>
                        <select class="neu-select" name="department" data-role="dropdownlist"
                            data-option-label="Select a Department" v-model="DepartmentId"
                            :class="[isMoveDisabled ? 'readOnly' : '', isDepartmentRequired ? 'hasError' : '']"
                            :disabled="isMoveDisabled" @change="departmentsOnChange">
                            <option v-for="dept in departments" :value="dept.value" :key="dept.value">
                                {{ dept.text }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isDepartmentRequired" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isDepartmentRequired" class="requiredTxt">Required</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Status</label>
                        <select class="neu-select" name="shifttype" :class="[isProductiveTypeRequired ? 'hasError' : '']"
                            data-role="dropdownlist" data-option-label="Select a Productive Type"
                            v-model="DropDownProductiveId" @change="productiveCodesOnChange">
                            <option v-for="code in productiveCodes" :value="code.fsProductiveID" :key="code.fsProductiveID">
                                {{ code.Description }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isProductiveTypeRequired" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isProductiveTypeRequired" class="requiredTxt">Required</span>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Shift Code </label>
                        <select class="neu-select" name="shiftcode" data-role="dropdownlist"
                            data-option-label="Select a Shift Code"
                            :class="[isCancelled ? 'readOnly' : '', isShiftCodeRequired ? 'hasError' : '']"
                            v-model="DepartmentShiftId" :disabled="isCancelled" @change="shiftCodesOnChange">
                            <option v-for="code in shiftCodes" :value="code.deptShiftId" :key="code.deptShiftId">
                                {{ code.deptShiftDescription }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isShiftCodeRequired" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isShiftCodeRequired" class="requiredTxt">Required</span>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Skill</label>
                        <select class="neu-select" name="skill" data-role="dropdownlist" data-option-label="Select a Skill"
                            :class="[isCancelled ? 'readOnly' : '', isSkillRequired ? 'hasError' : '']"
                            v-model="SkillLevelID" :disabled="isCancelled" @change="skillsOnChange">
                            <option v-for="skil in skills" :value="skil.SkillLevelID" style="cursor: pointer;"
                                :key="skil.SkillLevelID" :class="[!skil.isStaffSkill ? 'skill-color' : '']"
                                :title="!skil.isStaffSkill ? 'Select temporary covering skill' : ''">
                                {{ skil.SkillDescription }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isSkillRequired" class="requiredControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isSkillRequired" class="requiredTxt">Required</span>
                        <span class="invalid-msg" v-show="invalidSkill">
                            <font-awesome-icon :icon="['fas', 'circle-exclamation']" size="sm" class="mr-2" />
                            Selected skill is not assigned to department, please select another skill
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="font-weight-bold">Date</label>
                        <Datepicker v-model="ShiftDate" inputClassName="dp-custom-input" weekStart="0"
                            :enableTimePicker="false" :class="[isSplit ? 'readOnly' : '']" :disabled="isSplit"
                            @change="dateTimeOnChange" autoApply></Datepicker>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Start Time</label>
                                <vue-timepicker format="h:mm A" name="startTime" v-model="ShiftStartTime"
                                    :class="[isStandardShift ? 'readOnly' : '']" :disabled="isStandardShift"
                                    :minute-interval = "MinuteInterval"
                                    :key="counterStartTime" manual-input auto-scroll close-on-complete
                                    @close="startTimeOnChange">
                                </vue-timepicker>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="form-group" v-show="showEndTime">
                                    <label class="font-weight-bold">End Time</label>
                                    <vue-timepicker format="h:mm A" name="endTime" v-model="ShiftEndTime"
                                        :class="[isStandardShift ? 'readOnly' : '']" :disabled="isStandardShift"
                                         :minute-interval = "MinuteInterval"
                                        :key="counterEndTime" manual-input auto-scroll close-on-complete
                                        @close="endTimeOnChange">
                                    </vue-timepicker>
                                </div>
                            </div>
                            <div>
                                <div class="form-group" v-show="showNonProductiveTime">
                                    <label class="font-weight-bold">Duration</label>
                                    <div class="">
                                        <div class="row">
                                            <div class="form-group col">
                                                <neu-input name="hours" :value="DurationHours" inputmode="numeric"
                                                    type="number" :max="24" :min="0"
                                                    :class="[isDurationHoursRequired || isDurationHoursInvalid ? 'hasError' : '']"
                                                    :disabled="isDurationDisabled" @change="OnChangeValueDateTime($event,'DurationHours')">
                                                </neu-input>                                                                                               
                                                <small class="form-text text-muted">Hour(s)</small>
                                                <neu-icon v-if="isDurationHoursRequired"
                                                    class="requiredDurationControl material-icons"
                                                    aria-hidden="true">error</neu-icon>
                                                <span v-if="isDurationHoursRequired" class="requiredTxt">Required</span>
                                                <neu-icon v-if="isDurationHoursInvalid"
                                                    class="requiredDurationControl material-icons"
                                                    aria-hidden="true">error</neu-icon>
                                                <span v-if="isDurationHoursInvalid" class="requiredTxt">Invalid</span>
                                            </div>
                                            <div class="form-group col">
                                                <neu-input name="minutes" :value="DurationMinutes" inputmode="numeric"
                                                    type="number" :max="60" :min="0" :step="15"
                                                    :class="[isDurationMinutesRequired || isDurationMinutesInvalid ? 'hasError' : '']"
                                                    :disabled="isDurationDisabled" @change="OnChangeValueDateTime($event,'DurationMinutes')">
                                                </neu-input>
                                                <small class="form-text text-muted">Minute(s)</small>
                                                <neu-icon v-if="isDurationMinutesRequired"
                                                    class="requiredDurationControl material-icons"
                                                    aria-hidden="true">error</neu-icon>
                                                <span v-if="isDurationMinutesRequired" class="requiredTxt">Required</span>
                                                <neu-icon v-if="isDurationMinutesInvalid"
                                                    class="requiredDurationControl material-icons"
                                                    aria-hidden="true">error</neu-icon>
                                                <span v-if="isDurationMinutesInvalid" class="requiredTxt">Invalid</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="font-weight-bold">Charge</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" id="charge_yes" name="isCharge"
                                                :value="true" data-type="boolean" @change="OnChargeChange" v-model="Charge"
                                                :disabled="isChargeDisabled" /> Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" id="charge_no" name="isCharge"
                                                :value="false" data-type="boolean" @change="OnChargeChange" v-model="Charge"
                                                :disabled="isChargeDisabled" /> No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col" v-show="isFloatVisible">
                            <div class="form-group">
                                <label class="font-weight-bold">Float</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" id="float_yes" name="isFloat"
                                                :value="true" data-type="boolean" v-model="IsFloat"
                                                :disabled="isFloatDisabled" @change="floatSelection" /> Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" id="float_no" name="isFloat"
                                                :value="false" data-type="boolean" v-model="IsFloat"
                                                :disabled="isFloatDisabled" @change="floatSelection" /> No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6" v-show="showCancelOptions">
                    <div class="form-group">
                        <label class="font-weight-bold">Cancellation Reason</label>
                        <select class="neu-select" name="cancelReason" :class="[isCancelReasonsRequired ? 'hasError' : '']"
                            data-role="dropdownlist" v-model="CancelReasonId" @change="cancelReasonsOnChange">
                            <option v-for="reason in cancelReasons" :value="reason.reasonId" :key="reason.reasonId">
                                {{ reason.reason }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                        <neu-icon v-if="isCancelReasonsRequired" class="requiredCancelRsnControl material-icons" aria-hidden="true">error</neu-icon>
                        <span v-if="isCancelReasonsRequired" class="requiredTxt">Required</span>
                    </div>
                </div>
                <div class="col" v-show="showUnscheduledOptions">
                    <div class="form-group">
                        <label class="font-weight-bold">Unscheduled Absence</label>
                        <select class="neu-select" name="unscheduledAbsence" data-role="dropdownlist"
                            v-model="UnscheduledAbsenceOptionId" @change="uaOptionsOnChange">
                            <option v-for="uao in uaOptions" :value="uao.value" :key="uao.value">
                                {{ uao.text }}
                            </option>
                        </select>
                        <neu-icon class="material-icons neu-input__select-icon z-max">arrow_drop_down</neu-icon>
                    </div>
                </div>
            </div>
            <div class="row" v-if="isloadPTOBalance">
                <div class="col-sm">
                    <div class="form-group">
                        <div id="gridPTOBalance">
                            <PTOBalance :StaffId="staffIdPTO" :ShiftDate="shiftDatePTO" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <div class="form-group">
                <label class="font-weight-bold">Note</label>
                <neu-textarea rows="4" maxlength="500" placeholder="Add an assignment note." name="note" v-model="Note"
                    :class="[isNoteTxtRequired ? 'hasError' : '']" @keyup="noteOnChange">
                </neu-textarea>
                <small class="form-text text-muted">Remaining <span>{{ NoteMaxLength - (Note != undefined && Note != "" &&
                    Note != null ? Note.length : 0) }}</span>/<span>{{ NoteMaxLength }}</span></small>
                <span v-if="isNoteTxtRequired" class="requiredNoteControl material-icons" aria-hidden="true">error</span>
                <span v-if="isNoteTxtRequired" class="requiredTxt">Required</span>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//imports models and enums

//Import Componenets
import {
    NeuButton,
    NeuInput,
    NeuTextarea
} from "@neutron/vue";
import PTOBalance from './PTOBalance.vue'
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue';
//@ts-ignore
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Profile } from '@/models/profile/index';

class Props {
    assignmentEditInput!: any
    containerType!: any
}

@Options({
    computed: {
        ...mapState('shared', ['departmentSkillList', 'staffSkills', 'facilityList', 'departmentList']),
        ...mapState('actionable', ['deptCancelReasons', 'departmentShifts']),
        ...mapState('profile', ['profileData']),
    },
    components: {
        NeuButton,
        NeuInput,
        NeuTextarea,
        VueTimepicker,
        Datepicker,
        PTOBalance
    },
    watch: {
        ShiftDate(newDateVal) {
            this.dateTimeOnChange();
        }
    }
})
export default class AssignmentEdit extends Vue.with(Props) {
    //Data properties
    departmentSkillList!: any;
    staffSkills!: any;
    deptCancelReasons!: any;
    departmentShifts!: any;
    facilityList!: any;
    departmentList!: any;
    profileData!: Profile;
    deptSkills: any = null;
    staffSkillsList: any = null;

    shortDateFormatPresentation: string = 'MM-DD-YYYY';
    shortDateTimeFormat: string = 'YYYY-MM-DD h:mm a';
    // Assignment data
    ActualEndTime: any = null;
    ActualStartTime: any = null;
    AssignId: any = null;
    AssignmentDateCreated: any = null;
    AssignmentDateChanged: any = null;
    CancelComment: any = null;
    CancelReason: any = null;
    CancelReasonId: any = null;
    Charge: boolean = false;
    ClusterId: any = null;
    ClusterName: any = null;
    CountTypeId: any = null;
    CredentialCode: any = null;
    CredentialNumber: any = null;
    CredentialRenewalDate: any = null;
    CredentialTrackingStatusId: any = null;
    ShiftDate: any = null;
    Day: any = null;
    DepartmentCode: any = null;
    DepartmentId: any = null;
    DepartmentName: any = null;
    DepartmentShiftId: any = null;
    DurationHours: number = 0;
    DurationMinutes: number = 0;
    EmployeeName: any = null;
    EmployeeType: any = null;
    EndTime: any = null;
    FacilityId: any = null;
    FacilityName: any = null;
    FTE: any = null;
    FirstName: any = null;
    HireDate: any = null;
    Hours: any = null;
    IsCancelled: boolean = false;
    IsFloat: boolean = false;
    DefaultIsFloat: boolean = false;
    IsOvertime: boolean = false;
    LastName: any = null;
    NeedStatusId: any = null;
    OrigDepartmentCode: any = null;
    OrigDepartmentId: any = null;
    OrigDepartmentName: any = null;
    OverTimeOccurence: any = null;
    OvertimeShift: boolean = false;
    ProductiveDescription: any = null;
    ProductiveId: any = null;
    DropDownProductiveId: any = null;
    ProfileCharge: boolean = false;
    SeniorityDate: any = null;
    ServiceLineId: any = null;
    ShiftCode: any = null;
    SkillLevelDescription: any = null;
    SkillLevelID: any = null;
    SkillSort: any = null;
    StaffId: any = null;
    StaffShift: any = null;
    StartTime: any = null;
    UnscheduledAbsence: boolean = false;
    UnscheduledAbsenceText: any = null;
    UnscheduledAbsenceOptionId: any = null;
    Note: any = null;
    NoteMaxLength: number = 500;
    NoteRemainingLength: any = null;
    StaffOrigDepartmentId: any = null;
    floatChangedByUser: number = 0;
    AssociationType: any = null;
    isFloatVisible: boolean = false;
    DisplayDate: string = "";
    DisplayStartTime: string = "";
    DisplayEndTime: string = "";
    ShiftDuration: string = "";
    MinuteInterval: any = 1;

    // Form states
    Title: any = null;
    Status: any = null;
    show: boolean = false;
    showTitle: boolean = false;
    showEndTime: boolean = true;
    showNonProductiveTime: boolean = false;
    showCancelOptions: boolean = false;
    showUnscheduledOptions: boolean = false;
    showStatus: boolean = false;
    showNote: boolean = false;
    isChanged: boolean = false;
    isCreate: boolean = false;
    isSplit: boolean = false;
    isStandardShift: boolean = true;
    isChargeDisabled: boolean = true;
    isSkillDisabled: boolean = false;
    isFloatDisabled: boolean = false;
    isDurationDisabled: boolean = false;
    isMoveDisabled: boolean = false;
    isShiftCodesFiltered: boolean = false;
    isNoteRequired: boolean = false;
    isProductiveChange: boolean = false;
    isCancelled: boolean = false;
    isDurationValid: boolean = true;
    counterStartTime: number = 0;
    counterEndTime: number = 0;
    invalidSkill: boolean = false;
    isloadPTOBalance: boolean = false;
    ShiftStartTime: any = {
        "h": "",
        "mm": "",
        "A": ""
    };
    ShiftEndTime: any = {
        "h": "",
        "mm": "",
        "A": ""
    };
    staffIdPTO: any = null;
    shiftDatePTO: any = null;
    OriginalCharge: boolean = false;

    // Lists
    facilities: any = [];
    serviceLines: any = [];
    departments: any = [];
    skills: any = [];
    departmentSkills: any = [];
    productiveCodes: any = [
        { fsProductiveID: -1, Description: 'Select a Productive Type' },
        { fsProductiveID: 1, Description: 'Productive' },
        { fsProductiveID: 2, Description: 'Other-productive' },
        { fsProductiveID: 3, Description: 'Non-productive' },
        { fsProductiveID: 4, Description: 'Call' },
        { fsProductiveID: 5, Description: 'Placeholder' },
        { fsProductiveID: 6, Description: 'Cancelled' }
    ];
    shiftCodes: any = [];
    cancelReasons: any = [];
    uaOptions: any = [
        { value: 0, text: 'N/A' },
        { value: 1, text: 'Employee made contact' },
        { value: 2, text: 'No show / No call' },
    ];

    // Shift Types
    SHIFT_TYPE_NON_STANDARD: number = 0;
    SHIFT_TYPE_PRODUCTIVE: number = 1;
    SHIFT_TYPE_OTHER_PRODUCTIVE: number = 2;
    SHIFT_TYPE_NON_PRODUCTIVE: number = 3;
    SHIFT_TYPE_CALL: number = 4;
    SHIFT_TYPE_PLACEHOLDER: number = 5;
    SHIFT_TYPE_CANCELLED: number = 6;

    EMPLOYEE_TYPE_CORE = 'Core';

    //Validation Flag
    isFacilityRequired: boolean = false;
    isDepartmentRequired: boolean = false;
    isProductiveTypeRequired: boolean = false;
    isShiftCodeRequired: boolean = false;
    isSkillRequired: boolean = false;
    isDurationHoursRequired: boolean = false;
    isDurationMinutesRequired: boolean = false;
    isNoteTxtRequired: boolean = false;
    isCancelReasonsRequired: boolean = false;
    isDurationHoursInvalid: boolean = false;
    isDurationMinutesInvalid: boolean = false;

    //Lifecycle hooks
    async mounted() {
        this.loadAssignmentEdit();
    }

    //Methods
    loadAssignmentEdit() {
        this.mapData();
        this.modifiedAssignmentData();
    }

    mapData() {
        var _this = this;
        var isCancelled = this.getValue(this.assignmentEditInput.isCancelled);
        var facilityId = this.getValue(this.assignmentEditInput.facilityId);
        var facilityName = this.getValue(this.assignmentEditInput.facilityName);
        var clusterId = this.getValue(this.assignmentEditInput.clusterId);
        var clusterName = this.getValue(this.assignmentEditInput.clusterName);
        var deptId = this.getValue(this.assignmentEditInput.departmentId);
        var deptName = this.getValue(this.assignmentEditInput.departmentName);
        var date = moment(this.getValue(this.assignmentEditInput.date));
        var startTime = moment(this.getValue(this.assignmentEditInput.startTime));
        var endTime = moment(this.getValue(this.assignmentEditInput.endTime));
        var shiftDuration = endTime.diff(startTime, 'hours', true);
        var staffId = this.getValue(this.assignmentEditInput.staffId);
        var productiveId = this.getValue(this.assignmentEditInput.productiveId);
        var dropDownProductiveId = isCancelled ? 6 : this.getValue(this.assignmentEditInput.productiveId);
        var productiveTypeDescription = null;
        var skillLevelDescription = null;
        var UnscheduledAbsenceText = null;
        var UnscheduledAbsence = this.getValue(this.assignmentEditInput.unscheduledAbsence);
        var UnscheduledAbsenceOptionId = this.getValue(this.assignmentEditInput.unscheduledAbsenceOption) === null ? 0 : this.getValue(this.assignmentEditInput.unscheduledAbsenceOption);
        var SkillLevelID = this.getValue(this.assignmentEditInput.skillLevelId);
        var cancelReasonId = this.getValue(this.assignmentEditInput.cancelReasonId) === null ? -1 : this.getValue(this.assignmentEditInput.cancelReasonId);
        var departmentShiftId = this.getValue(this.assignmentEditInput.departmentShiftId) === null ? 0 : this.getValue(this.assignmentEditInput.departmentShiftId);
        var shiftCode = departmentShiftId === 0 ? '???' : this.getValue(this.assignmentEditInput.shiftCode);

        if (facilityId == null) { facilityId = this.getValue(this.assignmentEditInput.actionableDepartment.FacilityId); }
        if (facilityName == null) { facilityName = this.getValue(this.assignmentEditInput.actionableDepartment.FacilityName); }
        if (clusterId == null) { clusterId = this.getValue(this.assignmentEditInput.actionableDepartment.ClusterId); }
        if (clusterName == null) { clusterName = this.getValue(this.assignmentEditInput.actionableDepartment.ClusterName); }
        if (deptId == null) { deptId = this.getValue(this.assignmentEditInput.actionableDepartment.DepartmentId); }
        if (deptName == null) { deptName = this.getValue(this.assignmentEditInput.actionableDepartment.DepartmentName); }

        if (productiveId != null) {
            if (this.getBooleanValue(this.assignmentEditInput.isSplit)) {
                //@ts-ignore
                var placeHolderProdCode: any = jslinq(this.productiveCodes)
                    .where((data: any) => {
                        if (data.fsProductiveID == 4) { //Call productive code
                            return data;
                        }
                    })
                    .toList();
            }

            //@ts-ignore
            var shiftType: any = jslinq(this.productiveCodes)
                .where((data: any) => {
                    if (data.fsProductiveID == productiveId) {
                        return data;
                    }
                })
                .toList();

            this.ProductiveDescription = (shiftType != null && shiftType.length > 0) ? shiftType[0].Description : '';
        }

        if (UnscheduledAbsenceOptionId != null) {
            //@ts-ignore
            var uaOption: any = jslinq(this.uaOptions)
                .where((data: any) => {
                    if (data.value == UnscheduledAbsenceOptionId) {
                        return data;
                    }
                })
                .toList();

            if (uaOption != null && uaOption.length > 0) {
                UnscheduledAbsenceText = uaOption[0].text;
                this.UnscheduledAbsenceText = UnscheduledAbsenceText;
                this.UnscheduledAbsence = UnscheduledAbsence;
                this.UnscheduledAbsenceOptionId = UnscheduledAbsenceOptionId;
            }
        }

        if (deptId != null) {
            this.loadDepartmentSkills(deptId);
        }

        if (SkillLevelID != null) {
            this.loadStaffSkills();
        }

        this.ActualEndTime = this.getValue(this.assignmentEditInput.actualEndTime);
        this.ActualStartTime = this.getValue(this.assignmentEditInput.actualStartTime);
        this.AssignId = this.getValue(this.assignmentEditInput.assignId);
        this.AssignmentDateCreated = this.getValue(this.assignmentEditInput.assignmentDateCreated);
        this.AssignmentDateChanged = this.getValue(this.assignmentEditInput.assignmentDateChanged);
        this.CancelComment = this.getValue(this.assignmentEditInput.cancelComment);
        this.CancelReason = this.getValue(this.assignmentEditInput.cancelReason);
        this.CancelReasonId = cancelReasonId;
        this.Charge = this.getValue(this.assignmentEditInput.charge) == undefined ? false : this.getValue(this.assignmentEditInput.charge);
        this.ClusterId = clusterId;
        this.ClusterName = clusterName;
        this.CountTypeId = this.getValue(this.assignmentEditInput.countTypeId);
        this.CredentialCode = this.getValue(this.assignmentEditInput.credentialCode);
        this.CredentialNumber = this.getValue(this.assignmentEditInput.credentialNumber);
        this.CredentialRenewalDate = this.getValue(this.assignmentEditInput.credentialRenewalDate);
        this.CredentialTrackingStatusId = this.getValue(this.assignmentEditInput.credentialTrackingStatusId);
        this.ShiftDate = date.format('MM/DD/YYYY');
        this.Day = this.getValue(this.assignmentEditInput.day);
        this.DepartmentCode = this.getValue(this.assignmentEditInput.departmentCode);
        this.DepartmentId = deptId == null ? -1 : deptId;
        this.DepartmentName = deptName;
        this.DepartmentShiftId = departmentShiftId == null ? -1 : departmentShiftId;
        this.EmployeeName = this.getValue(this.assignmentEditInput.lastName) + ', ' + this.getValue(this.assignmentEditInput.firstName);
        this.EmployeeType = this.getValue(this.assignmentEditInput.employeeType);
        this.EndTime = this.getValue(this.assignmentEditInput.endTime);
        this.FacilityId = facilityId == null ? -1 : facilityId;
        this.FacilityName = facilityName;
        this.FTE = this.getValue(this.assignmentEditInput.fte);
        this.FirstName = this.getValue(this.assignmentEditInput.firstName);
        this.HireDate = this.getValue(this.assignmentEditInput.hireDate);
        this.Hours = this.getValue(this.assignmentEditInput.hours);
        this.IsCancelled = this.getValue(this.assignmentEditInput.isCancelled);
        this.IsFloat = this.getValue(this.assignmentEditInput.isFloat) ? this.assignmentEditInput.isFloat : false;
        this.DefaultIsFloat = this.getValue(this.assignmentEditInput.isFloat) ? this.assignmentEditInput.isFloat : false;
        this.IsOvertime = this.getValue(this.assignmentEditInput.isOvertime);
        this.LastName = this.getValue(this.assignmentEditInput.lastName);
        this.NeedStatusId = this.getValue(this.assignmentEditInput.needStatusId);
        this.OrigDepartmentCode = this.getValue(this.assignmentEditInput.origDepartmentCode);
        this.OrigDepartmentId = this.getValue(this.assignmentEditInput.origDepartmentId);
        this.OrigDepartmentName = this.getValue(this.assignmentEditInput.origDepartmentName);
        this.OverTimeOccurence = this.getValue(this.assignmentEditInput.overTimeOccurence);
        this.OvertimeShift = this.getValue(this.assignmentEditInput.overtimeShift);
        this.ProductiveId = productiveId;
        this.DropDownProductiveId = dropDownProductiveId == null ? -1 : dropDownProductiveId;
        this.ProfileCharge = this.getValue(this.assignmentEditInput.profileCharge);
        this.SeniorityDate = this.getValue(this.assignmentEditInput.seniorityDate);
        this.ServiceLineId = this.getValue(this.assignmentEditInput.serviceLineId);
        this.ShiftCode = shiftCode;
        this.SkillLevelID = this.getValue(this.assignmentEditInput.skillLevelId) == null ? -1 : this.getValue(this.assignmentEditInput.skillLevelId);
        this.SkillSort = this.getValue(this.assignmentEditInput.skillSort);
        this.StaffId = this.getValue(this.assignmentEditInput.staffId);
        this.StaffShift = this.getValue(this.assignmentEditInput.staffShift);
        this.StartTime = this.getValue(this.assignmentEditInput.startTime);
        this.StaffOrigDepartmentId = this.getValue(this.assignmentEditInput.staffOrigDepartmentId);
        this.counterStartTime = this.counterStartTime + 1;
        this.counterEndTime = this.counterEndTime + 1;

        this.isFloatVisible = true;
        this.floatChangedByUser = 0;
        this.AssociationType = this.getValue(this.assignmentEditInput.associationType);

        // reset note
        this.Note = null;
        this.NoteRemainingLength = null;

        // form states
        this.isSplit = this.getBooleanValue(this.assignmentEditInput.isSplit);
        this.isMoveDisabled = this.getBooleanValue(this.assignmentEditInput.isMoveDisabled);
        this.showCancelOptions = this.getValue(this.assignmentEditInput.isCancelled);
        this.showUnscheduledOptions = this.getValue(this.assignmentEditInput.isCancelled);

        // lists
        this.loadFacilities();
        this.loadDepartments(facilityId);
        this.loadDepartmentShifts(deptId, date);
        this.loadDepartmentCancelReasons(deptId);

        // formatted display fields
        this.DisplayDate = date.format('YYYY-MM-DD');
        this.DisplayStartTime = startTime.format('h:mm a');
        this.DisplayEndTime = endTime.format('h:mm a');
        this.ShiftDuration = '(' + shiftDuration.toFixed(2) + ' hours)';

        //Format time picker
        this.setTimePicker();

        this.shiftCodesOnDataBound();

        if (this.containerType.toLowerCase() == "splitAAssignment".toLowerCase() || this.containerType.toLowerCase() == "splitBAssignment".toLowerCase() || this.containerType.toLowerCase() == "createAssignment".toLowerCase()) {
            this.Title = this.assignmentEditInput.title;
            this.showTitle = this.assignmentEditInput.showTitle;
        }

        if (this.containerType.toLowerCase() == "createAssignment".toLowerCase()) {
            if (this.assignmentEditInput.tabName.toLowerCase() == "Staff Search".toLowerCase()) {
                this.DepartmentId = -1;
            }
            this.isCreate = true;
            this.OriginalCharge = this.assignmentEditInput.charge;
        }

        //Reset Validation Control
        this.isFacilityRequired = false;
        this.isDepartmentRequired = false;
        this.isProductiveTypeRequired = false;
        this.isShiftCodeRequired = false;
        this.isSkillRequired = false;
        this.isDurationHoursRequired = false;
        this.isDurationMinutesRequired = false;
        this.isNoteTxtRequired = false;
        this.isCancelReasonsRequired = false;
        this.isDurationHoursInvalid = false;
        this.isDurationMinutesInvalid = false;
    }

    setTimePicker() {
        //Start Time
        var shiftStartTime = moment(this.StartTime).format('h:mm A');
        var hoursStart: number = moment(shiftStartTime, 'h:mm A').hours();
        var timeTxtStart: string;
        if (hoursStart == 0 && shiftStartTime.split(" ")[1] == "AM") {
            hoursStart = 12;
        }

        if (hoursStart > 12) {
            hoursStart = hoursStart - 12;
            timeTxtStart = "PM";
        }
        else if (hoursStart == 12) {
            timeTxtStart = shiftStartTime.split(" ")[1];
        }
        else {
            timeTxtStart = "AM";
        }

        var minutes:number = moment(shiftStartTime, 'h:mm A').minutes() % 15;
            if (minutes < 8 ) {
                minutes = minutes + (-1 * ( moment(shiftStartTime, 'h:mm A').minutes() % 15));
            }
            else{
                minutes = minutes + (15- (moment(shiftStartTime, 'h:mm A').minutes() % 15));
            }

        var minutesStart: string = String(moment(shiftStartTime, 'h:mm A').minutes()).padStart(2, "0");

        this.counterStartTime = this.counterStartTime + 1;
        this.ShiftStartTime.h = hoursStart.toString();
        if(this.isSplit && (this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
        {
            this.ShiftStartTime.mm = minutesStart.toString();
        }
        else
        {
            this.ShiftStartTime.mm = minutes.toString().padStart(2, "0");
        }
        
        this.ShiftStartTime.A = timeTxtStart;

        if(this.isStandardShift == false && (this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
        {
            this.MinuteInterval = 15;
        }
        else{
            this.MinuteInterval = 1;
        }

        //End Time
        var shiftEndTime = moment(this.EndTime).format('h:mm A');
        var hoursEnd: number = moment(shiftEndTime, 'h:mm A').hours();
        var timeTxtEnd: string;
        if (hoursEnd == 0 && shiftEndTime.split(" ")[1] == "AM") {
            hoursEnd = 12;
        }

        if (hoursEnd > 12) {
            hoursEnd = hoursEnd - 12;
            timeTxtEnd = "PM";
        }
        else if (hoursEnd == 12) {
            timeTxtEnd = shiftEndTime.split(" ")[1];
        }
        else {
            timeTxtEnd = "AM";
        }
        var minutesEnd: string = String(moment(shiftEndTime, 'h:mm A').minutes()).padStart(2, "0");
        this.counterEndTime = this.counterEndTime + 1;
        this.ShiftEndTime.h = hoursEnd.toString();
        this.ShiftEndTime.mm = minutes.toString().padStart(2, "0");
        if(this.isSplit && !(this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
        {
            this.ShiftEndTime.mm  = minutesEnd.toString();
        }
        else
        {
            this.ShiftEndTime.mm  = minutes.toString().padStart(2, "0");
        }
        this.ShiftEndTime.A = timeTxtEnd;
    }

    getValue(obj: any) {
        if (typeof obj === undefined) {
            return null;
        }
        return obj;
    }

    getBooleanValue(obj: any) {
        if (typeof obj === undefined) { return false; }
        if (typeof obj === 'boolean') { return obj; }
        return false;
    }

    async loadDepartmentSkills(departmentId: any) {
        var input = {
            deptId: departmentId,
            date: moment(this.getValue(this.assignmentEditInput.date)).format(this.shortDateFormatPresentation),
            tenantId: this.profileData.TenantId
        }

        await this.$store
            .dispatch("shared/getDepartmentSkills", input)
            .then(() => {
                if (this.departmentSkillList != null && this.departmentSkillList.length > 0) {
                    this.deptSkills = this.departmentSkillList;

                    var skillData: any = [];

                    this.deptSkills.forEach((skill: any) => {
                        skillData.push(skill.skillLevelId);
                    });

                    this.departmentSkills = skillData;
                    this.loadStaffSkills();
                    this.modifiedAssignmentData();
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving Department Skills.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationAssignment', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadStaffSkills() {
        if (this.StaffId != null) {
            var inputs = {
                staffId: this.StaffId,
                tenantId: this.profileData.TenantId
            }

            await this.$store
                .dispatch("shared/getStaffSkills", inputs)
                .then(() => {
                    if (this.staffSkills != null && this.staffSkills.length > 0) {
                        this.staffSkillsList = this.staffSkills;

                        this.SkillLevelDescription = this.staffSkillsList[0].skillDescription;

                        var updatedSkills: any = [];
                        this.staffSkillsList.forEach((skillItem: any) => {
                            updatedSkills.push({ SkillLevelID: skillItem.skillLevelId, SkillDescription: skillItem.skillDescription, isStaffSkill: true });
                        });
                        if (this.deptSkills != null && this.deptSkills.length > 0) {
                            this.deptSkills.forEach((deptSkill: any) => {
                                var isStaffSkillLevel = updatedSkills.filter(
                                    function (item: any) {
                                        return (item.SkillLevelID == deptSkill.skillLevelId);
                                    })[0];

                                if (!isStaffSkillLevel) {
                                    updatedSkills.push({ SkillLevelID: deptSkill.skillLevelId, SkillDescription: deptSkill.description, isStaffSkill: false });
                                }
                            });

                        }
                        this.skills = updatedSkills;
                        this.skills.unshift({
                            SkillLevelID: -1,
                            SkillDescription: 'Select a Skill',
                            isStaffSkill: true
                        });
                    }
                    else {
                        this.SkillLevelDescription = this.assignmentEditInput.skillDescription;
                    }
                    this.SkillLevelID = this.assignmentEditInput.skillLevelId;
                    this.SkillLevelDescription = this.assignmentEditInput.skillLevelDescription;
                    this.modifiedAssignmentData();
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Staff Skills.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
        }
    }

    async loadDepartmentCancelReasons(deptId: any) {
        var inputData = {
            deptId: deptId,
            tenantId: this.profileData.TenantId
        }

        await this.$store
            .dispatch("actionable/getDepartmentCancelReasons", inputData)
            .then(() => {
                if (this.deptCancelReasons != null && this.deptCancelReasons.length > 0) {
                    this.cancelReasons = this.deptCancelReasons;

                    this.cancelReasons.unshift({
                        reasonId: -1,
                        reason: 'Select a Cancel Reason'
                    });
                }
            })
            .catch((err: any) => {
                if (err) {
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartmentShifts(deptId: any, date: any) {
        var inputData = {
            departmentId: deptId,
            date: moment(date).format('YYYY-MM-DD')
        }
        await this.$store
            .dispatch("actionable/getDepartmentShifts", inputData)
            .then(() => {
                if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                    this.shiftCodes = JSON.parse(JSON.stringify(this.departmentShifts));
                    this.setDepartmentShifts();
                    this.shiftCodesOnDataBound();
                }

                this.shiftCodes.unshift({
                    deptShiftId: -1,
                    deptShiftDescription: 'Select a Shift Code'
                });
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving Department Shift.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationAssignment', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    setDepartmentShifts() {
        this.shiftCodes.forEach((element: any, index: any) => {
            if (this.shiftCodes[index].code.toLowerCase() === 'Other'.toLowerCase()) {
                this.shiftCodes.splice(index, 1);
                return false;
            }
        });

        this.shiftCodes.push({
            code: '???',
            deptShiftId: 0,
            deptShiftDescription: '???',
            productiveId: 0
        });

        this.shiftCodes.unshift({
            deptShiftId: -1,
            deptShiftDescription: 'Select a Shift Code'
        });

        var productiveIds = this.shiftCodes.map(function (elem: any) { return elem.productiveId; });

        productiveIds = productiveIds.filter(function (value: any, index: any, self: any) {
            return self.indexOf(value) === index;
        });

        productiveIds.unshift(-1);

        this.productiveCodes = this.productiveCodes.filter(function (value: any, index: any, self: any) {
            return value.fsProductiveID === 6 || productiveIds.indexOf(value.fsProductiveID) >= 0;
        });
    }

    async loadFacilities() {
        await this.$store
            .dispatch("shared/getAllFacilities", this.profileData.TenantId)
            .then(() => {
                if (this.facilityList != null && this.facilityList.length > 0) {
                    this.facilities = this.facilityList;

                    this.facilities.unshift({
                        value: -1,
                        text: 'Select a Facility'
                    });
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving facilities.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationAssignment', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    async loadDepartments(facilityId: any) {
        var inputData = {
            facilityId: facilityId,
            tenantId: this.profileData.TenantId
        }
        await this.$store
            .dispatch("shared/getDepartmentsByFacility", inputData)
            .then(() => {
                if (this.departmentList != null && this.departmentList.length > 0) {
                    this.departments = this.departmentList;

                    this.departments.unshift({
                        value: -1,
                        text: 'Select a Department'
                    });
                }
            })
            .catch((err: any) => {
                if (err) {
                    var errorData = {
                        message: "An error occured while retrieving departments.",
                        flag: "error"
                    }
                    this.emitter.emit('showToasterNotificationAssignment', errorData);
                    console.error(err); // Handle errors any way you want
                }
            });
    }

    facilitiesOnChange() {
        //@ts-ignore
        var selectedFacility: any = jslinq(this.facilities)
            .where((data: any) => {
                if (data.value == this.FacilityId) {
                    return data;
                }
            })
            .toList();

        if (selectedFacility != null && selectedFacility.length > 0) {
            if (selectedFacility[0].value != -1) {
                this.loadDepartments(selectedFacility[0].value);
            }
            this.FacilityName = selectedFacility[0].text;
            this.ClusterName = "Undefined";
            this.DepartmentId = -1;

            if (!this.isCreate) {
                this.isChanged = true;
            }
        }
        this.modifiedAssignmentData();

        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    departmentsOnChange() {
        //@ts-ignore
        var selectedDepartment: any = jslinq(this.departments)
            .where((data: any) => {
                if (data.value == this.DepartmentId) {
                    return data;
                }
            })
            .toList();

        if (selectedDepartment != null && selectedDepartment.length > 0) {
            this.isShiftCodesFiltered = false;
            this.DepartmentName = selectedDepartment[0].text;

            if (selectedDepartment[0].value != -1) {
                this.loadDepartmentShifts(selectedDepartment[0].value, this.ShiftDate);
                this.ClusterName = this.ServiceLineByDepartmentId(selectedDepartment[0].value);
                this.loadDepartmentSkills(selectedDepartment[0].value);
            }
            this.DepartmentShiftId = -1;
            if (!this.isCreate) {
                this.isChanged = true;
            }
        }
        this.modifiedAssignmentData();

        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }
    ServiceLineByDepartmentId(id: any) {
        var name = 'Undefined';
        var config = this.assignmentEditInput.config;
        config.forEach((fac: any) => {
            fac.Clusters.forEach((clu: any) => {
                if (clu.Departments.map((d: any) => { return Number(d.Value); }).indexOf(Number(id)) > -1) {
                    name = clu.Text;
                }
            });
        });
        return name;
    }

    productiveCodesOnChange() {
        //@ts-ignore
        var selectedProductiveCodes: any = jslinq(this.productiveCodes)
            .where((data: any) => {
                if (data.fsProductiveID == this.DropDownProductiveId) {
                    return data;
                }
            })
            .toList();
        if (selectedProductiveCodes != null && selectedProductiveCodes.length > 0 && this.DropDownProductiveId != -1) {
            if (this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED && !this.isCreate && !this.isSplit) {
                this.resetCancelShiftType();
            }

            var previousValue: any = this.assignmentEditInput && this.isCancelled ? 6 : this.assignmentEditInput.productiveId;
            if (previousValue === this.SHIFT_TYPE_CANCELLED || this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED) {
                this.IsCancelled = this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED ? true : false;
                this.cancelledOnChange();
                this.ProductiveId = this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED ? previousValue : this.DropDownProductiveId;
                this.ProductiveDescription = this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED ? "Cancelled" : selectedProductiveCodes[0].Description;
            }

            this.setFloatControls(this.DropDownProductiveId);
            var date: any = null;

            if (this.DropDownProductiveId !== this.SHIFT_TYPE_CANCELLED) {
                var selectedText = selectedProductiveCodes[0].Description;
                date = moment(this.ShiftDate).format('YYYY-MM-DD');
                this.isShiftCodesFiltered = false;
                this.DepartmentShiftId = -1;
                this.ShiftCode = null;
                this.ProductiveId = this.DropDownProductiveId;
                this.ProductiveDescription = selectedText;
                this.showCancelOptions = false;
                this.showUnscheduledOptions = false;

                if (this.DropDownProductiveId === this.SHIFT_TYPE_NON_PRODUCTIVE && !this.isCreate && this.assignmentEditInput.productiveId !== this.SHIFT_TYPE_NON_PRODUCTIVE) {
                    this.showCancelOptions = true;
                    this.showUnscheduledOptions = true;                    
                }

                if((this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
                {
                    this.MinuteInterval = 15;
                }
                else{
                    this.MinuteInterval = 1;
                }

                if (!this.isCreate) {
                    this.isChanged = true;
                }

                if (this.isSplit) {
                    var startTime = this.StartTime;
                    var endTime = this.EndTime;

                    this.isShiftCodesFiltered = true;
                    this.filterShiftsByDateRange(startTime, endTime, this.DropDownProductiveId);
                }
                else {
                    this.isShiftCodesFiltered = true;
                    this.filterShiftsByProductiveType(this.DropDownProductiveId);
                }


                if (this.EmployeeType === this.EMPLOYEE_TYPE_CORE) {
                    this.LoadPtoBalanceData(this.DropDownProductiveId, null, this.StaffId, date);
                }
            }
        }
        this.modifiedAssignmentData();
        this.shiftCodesOnDataBound();

        //For Split Shift, Display appropriate message if no shift is currently selected
        if (this.isSplit) {
            if (this.DepartmentShiftId === null || this.DepartmentShiftId == -1) {

                // Account for non-standard shift added to the dataset.
                var shiftCount = (this.shiftCodes.length - 1);

                if (shiftCount === 0) {
                    var errorData = {
                        message: "No matching shifts found.",
                        flag: "warning"
                    }
                    this.emitter.emit('showToasterNotificationAssignment', errorData);
                }

            }
        }
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    LoadPtoBalanceData(productiveCodeId: any, kronosPayCode: any, staffId: any, shiftDate: any) {
        var KRONOS_PAY_CODE: any = this.getPayCodesForPTO();

        this.isloadPTOBalance = false;

        if (KRONOS_PAY_CODE.indexOf(kronosPayCode) > -1
            && productiveCodeId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            this.staffIdPTO = staffId;
            this.shiftDatePTO = shiftDate;
            this.isloadPTOBalance = true;
        }
    }

    getPayCodesForPTO() {
        return ["LVPTO", "PTO", "NBLVP", "NBPTO", "SOU", "PTOK", "PTO-S", "UNPTO"];
    }

    cancelledOnChange() {
        var selectedValue = this.IsCancelled;

        this.showCancelOptions = selectedValue;
        this.showUnscheduledOptions = selectedValue;

        if (!this.isCreate) {
            this.isChanged = true;
        }

        if (!selectedValue) {
            this.CancelReasonId = -1;
            this.CancelReason = null;
            this.UnscheduledAbsence = false;
            this.UnscheduledAbsenceText = null;
            this.UnscheduledAbsenceOptionId = 0;
        }

        this.isCancelled = selectedValue;
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    resetCancelShiftType() {
        var data = this.assignmentEditInput;
        // Check to see if the isCancel is select. If so, need to reset all values back to original state
        var facilityId = this.getValue(data.facilityId);
        var facilityName = this.getValue(data.facilityName);
        var clusterId = this.getValue(data.clusterId);
        var clusterName = this.getValue(data.clusterName);
        var deptId = this.getValue(data.departmentId);
        var deptName = this.getValue(data.departmentName);
        var date = moment(this.getValue(data.date));
        var departmentShiftId = this.getValue(data.departmentShiftId) === null ? 0 : this.getValue(data.departmentShiftId);
        var shiftCode = departmentShiftId === 0 ? '???' : this.getValue(data.shiftCode);
        var isCancelled = this.getValue(data.isCancelled);
        var isMoveDisable = this.getValue(data.isMoveDisabled);
        var isSplit = this.getBooleanValue(data.isSplit);

        if (facilityId == null) { facilityId = this.getValue(data.actionableDepartment.FacilityId); }
        if (facilityName == null) { facilityName = this.getValue(data.actionableDepartment.FacilityName); }
        if (clusterId == null) { clusterId = this.getValue(data.actionableDepartment.ClusterId); }
        if (clusterName == null) { clusterName = this.getValue(data.actionableDepartment.ClusterName); }
        if (deptId == null) { deptId = this.getValue(data.actionableDepartment.DepartmentId); }
        if (deptName == null) { deptName = this.getValue(data.actionableDepartment.DepartmentName); }

        this.FacilityId = facilityId;
        this.FacilityName = facilityName;
        this.ClusterId = clusterId;
        this.ClusterName = clusterName;
        this.DepartmentId = deptId;
        this.DepartmentName = deptName;
        this.ShiftDate = date.format('MM/DD/YYYY');

        this.DepartmentShiftId = departmentShiftId;
        this.StartTime = this.getValue(data.startTime);
        this.EndTime = this.getValue(data.endTime);
        this.ShiftCode = shiftCode;
        this.loadFacilities();
        this.loadDepartments(facilityId);
        this.loadDepartmentShifts(deptId, date);
        this.isCancelled = isCancelled;
        this.isMoveDisabled = isMoveDisable;
        this.isSplit = isSplit;

        this.setTimePicker();
    }

    filterShiftsByProductiveType(productiveTypeId: any) {
        this.shiftCodes = JSON.parse(JSON.stringify(this.departmentShifts));
        //@ts-ignore
        this.shiftCodes = jslinq(this.shiftCodes)
            .where((data: any) => {
                if (data.productiveId == 0 || data.productiveId == productiveTypeId) {
                    return data;
                }
            })
            .toList();

        this.shiftCodes.forEach((element: any, index: any) => {
            if (this.shiftCodes[index].code.toLowerCase() === 'Other'.toLowerCase()) {
                this.shiftCodes.splice(index, 1);
                return false;
            }
        });

        this.shiftCodes.push({
            code: '???',
            deptShiftId: 0,
            deptShiftDescription: '???',
            productiveId: 0
        });

        this.shiftCodes.unshift({
            deptShiftId: -1,
            deptShiftDescription: 'Select a Shift Code'
        });
    }

    filterShiftsByDateRange(startTime: any, endTime: any, productiveTypeId: any) {
        var startDateTime = moment(startTime);
        var endDateTime = moment(endTime);

        if (productiveTypeId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            this.filterShiftsByProductiveType(productiveTypeId);
            return;
        }

        if (startDateTime.isValid() && endDateTime.isValid()) {
            var date = moment('1900-01-01', 'YYYY-MM-DD');
            var parsedStartTime: any = moment(date.format('YYYY-MM-DD') + 'T' + startDateTime.format('HH:mm:ss'));  //.format('YYYY-MM-DDTHH:mm:ss');
            var parsedEndTime: any = moment(date.format('YYYY-MM-DD') + 'T' + endDateTime.format('HH:mm:ss'));    //.format('YYYY-MM-DDTHH:mm:ss');

            if (parsedStartTime.isAfter(parsedEndTime)) {
                parsedEndTime.add(1, 'd');
            }

            parsedStartTime = parsedStartTime.format('YYYY-MM-DDTHH:mm:ss');
            parsedEndTime = parsedEndTime.format('YYYY-MM-DDTHH:mm:ss');

            this.shiftCodes = JSON.parse(JSON.stringify(this.departmentShifts));
            //@ts-ignore
            this.shiftCodes = jslinq(this.shiftCodes)
                .where((data: any) => {
                    if ((data.productiveId == 0) ||
                        (data.startTime == parsedStartTime && data.endTime == parsedEndTime && data.productiveId == productiveTypeId)) {
                        return data;
                    }
                })
                .toList();

            this.shiftCodes.forEach((element: any, index: any) => {
                if (this.shiftCodes[index].code.toLowerCase() === 'Other'.toLowerCase()) {
                    this.shiftCodes.splice(index, 1);
                    return false;
                }
            });

            this.shiftCodes.push({
                code: '???',
                deptShiftId: 0,
                deptShiftDescription: '???',
                productiveId: 0
            });
        }
    }

    setFloatControls(selectedValue: any) {
        this.floatChangedByUser = 0;

        if (selectedValue === this.SHIFT_TYPE_PRODUCTIVE) {
            this.isFloatDisabled = false;
        }
        else {
            this.IsFloat = false;
            this.DefaultIsFloat = false;
            this.isFloatDisabled = true;
        }
    }

    shiftCodesOnChange() {
        //@ts-ignore
        var selectedShiftCode: any = jslinq(this.shiftCodes)
            .where((data: any) => {
                if (data.deptShiftId == this.DepartmentShiftId) {
                    return data;
                }
            })
            .toList();
        var kronosPayCode: any = null;
        var date: any = null;

        var selectedValue = Number(this.DepartmentShiftId);
        // Need to set ??? back to original split time for AssignmentB
        if (this.isSplit && selectedValue === 0 && this.assignmentEditInput.isAssignmentB) {
            this.StartTime = moment(this.assignmentEditInput.startTime).toDate();
            this.EndTime = moment(this.assignmentEditInput.endTime).toDate();
        }

        if (this.isSplit && selectedValue !== 0 && this.ProductiveId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            this.StartTime = moment(this.assignmentEditInput.startTime).toDate();
            this.EndTime = moment(this.assignmentEditInput.endTime).toDate();
        }

        if (selectedShiftCode != null && selectedShiftCode.length > 0) {
            var shift = this.findShiftById(this.shiftCodes, selectedValue);
            date = moment(this.ShiftDate).format('YYYY-MM-DD');
            kronosPayCode = shift.code;

            // Could not find the selected shift, use the non-standard (???).
            if (shift === undefined || shift === null) {
                shift = this.findShiftById(this.shiftCodes, 0);
            }

            if (shift !== undefined && selectedValue !== 0 && this.ProductiveId !== this.SHIFT_TYPE_NON_PRODUCTIVE) {
                var shiftStartTime = moment(shift.startTime).format('h:mm a');
                var shiftEndTime = moment(shift.endTime).format('h:mm a');
                var formattedStartTime = moment(date + ' ' + shiftStartTime, this.shortDateTimeFormat);
                var formattedEndTime = moment(date + ' ' + shiftEndTime, this.shortDateTimeFormat);

                if (formattedStartTime.isValid() && formattedEndTime.isValid()) {

                    if (formattedEndTime.isBefore(formattedStartTime)) {
                        formattedEndTime.add(1, 'days');
                    }

                    this.StartTime = formattedStartTime.toDate();
                    this.EndTime = formattedEndTime.toDate();
                }
            }
            this.setTimePicker();
            this.ShiftCode = shift.code;
        }

        this.setFloatControls(this.ProductiveId);

        if (!this.isCreate
            && this.StaffOrigDepartmentId != this.DepartmentId
            && this.OrigDepartmentId != this.DepartmentId
            && this.ProductiveId === this.SHIFT_TYPE_PRODUCTIVE) {
            this.IsFloat = true;
            this.DefaultIsFloat = true;
            this.isFloatVisible = true;
        }

        if (!this.isCreate) {
            this.isChanged = true;
        }

        if (this.ProductiveId === this.SHIFT_TYPE_CANCELLED && !this.isCreate && !this.isSplit) {
            this.isCancelled = true;
        }
        else {
            this.isCancelled = false;
        }

        if (this.EmployeeType === this.EMPLOYEE_TYPE_CORE) {
            this.LoadPtoBalanceData(this.ProductiveId, kronosPayCode, this.StaffId, date);
        }

        this.shiftCodesOnDataBound();
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    findShiftById(shifts: any, id: any) {
        var shift;

        if (id == undefined) { return shift; }

        for (var i = 0; i < shifts.length; i++) {
            if (shifts[i].deptShiftId === id) {
                shift = shifts[i];
                break;
            }
        }

        return shift;
    }

    shiftCodesOnDataBound() {
        var shiftCodesCount = 0;

        if (this.shiftCodes != null) {
            shiftCodesCount = this.shiftCodes.length;
        }

        if (!this.isShiftCodesFiltered && shiftCodesCount > 0) {
            if (this.isSplit) {
                this.isShiftCodesFiltered = true;
                this.filterShiftsByDateRange(this.StartTime, this.EndTime, this.ProductiveId);
            }
            else {
                this.isShiftCodesFiltered = true;
                this.filterShiftsByProductiveType(this.ProductiveId);
            }
        }

        this.isShiftCodesFiltered = false;
    }

    skillsOnChange() {
        //@ts-ignore
        var selectedSkill: any = jslinq(this.skills)
            .where((data: any) => {
                if (data.SkillLevelID == this.SkillLevelID) {
                    return data;
                }
            })
            .toList();

        if (selectedSkill != null && selectedSkill.length > 0) {
            this.SkillLevelDescription = selectedSkill[0].SkillDescription;
            if (!this.isCreate) {
                this.isChanged = true;
            }
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    dateTimeOnChange() {
        if (this.DepartmentId != -1) {
            this.loadDepartmentShifts(this.DepartmentId, this.ShiftDate);
        }

        // calculate end time from duration if non-productive shift
        if (this.ProductiveId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            if (this.DurationHours === null) { this.DurationHours = 0; }
            if (this.DurationMinutes === null) { this.DurationMinutes = 0; }

            var date: any = moment(this.ShiftDate).format('YYYY-MM-DD');
            var startTime: any = moment(this.StartTime).format('hh:mm:ss');
            var endTime: any = moment(this.EndTime).format('hh:mm:ss');

            startTime = moment(date + ' ' + startTime);
            endTime = startTime.clone();
            endTime.add(this.DurationHours, 'hours').add(this.DurationMinutes, 'minutes');

            this.EndTime = endTime.toDate();
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    floatSelection() {
        if (this.floatChangedByUser == 0) {
            this.floatChangedByUser = 1;
        }
        else {
            this.floatChangedByUser = 0;
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    cancelReasonsOnChange() {
        //@ts-ignore
        var selectedCancelReason: any = jslinq(this.cancelReasons)
            .where((data: any) => {
                if (data.reasonId == this.CancelReasonId) {
                    return data;
                }
            })
            .toList();

        if (selectedCancelReason != null && selectedCancelReason.length > 0) {
            this.CancelReason = selectedCancelReason[0].reason;

            if (!this.isCreate) {
                this.isChanged = true;
            }
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    uaOptionsOnChange() {
        //@ts-ignore
        var selectedUAOptions: any = jslinq(this.uaOptions)
            .where((data: any) => {
                if (data.value == this.UnscheduledAbsenceOptionId) {
                    return data;
                }
            })
            .toList();

        if (selectedUAOptions != null && selectedUAOptions.length > 0) {
            this.UnscheduledAbsence = true;
            this.UnscheduledAbsenceText = selectedUAOptions[0].text;

            if (!this.isCreate) {
                this.isChanged = true;
            }
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    noteOnChange() {
        if (!this.isCreate) {
            this.isChanged = true;
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    startTimeOnChange() {
        if (!this.isCreate) {
            this.isChanged = true;
        }
        var splitTimeTxt = this.ShiftStartTime.h + ':' + this.ShiftStartTime.mm + ' ' + this.ShiftStartTime.A;
        this.StartTime = moment(splitTimeTxt, ["h:mm A"]);
      
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    endTimeOnChange() {
        if (!this.isCreate) {
            this.isChanged = true;
        }
        var splitTimeTxt = this.ShiftEndTime.h + ':' + this.ShiftEndTime.mm + ' ' + this.ShiftEndTime.A;
        this.EndTime = moment(splitTimeTxt, ["h:mm A"]);
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    OnChargeChange() {
        if (!this.isCreate) {
            this.isChanged = true;
        }
        this.modifiedAssignmentData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    formOnReset() {
        this.isChanged = false;
        this.mapData();
        var flag: boolean = this.validateForm();
        this.sendValidationFlag(flag);
    }

    validateForm(): boolean {
        this.isNoteRequired = false;
        this.isProductiveChange = false;
        this.isDurationValid = true;
        this.invalidSkill = false;
        var isChargeable = this.ProfileCharge || this.OriginalCharge;
        var parsedStartTime = moment(this.StartTime);
        var parsedEndTime = moment(this.EndTime);
        var shift: any = null;
        var showShiftCodeRequiredError: boolean = false;
        var isHoursMinutesInvalid: boolean = false;

        //Required Validation
        if (this.FacilityId == -1 || this.FacilityId == null) {
            this.isFacilityRequired = true;
            return false;
        }
        else {
            this.isFacilityRequired = false;
        }

        if (this.DepartmentId == -1 || this.DepartmentId == null) {
            this.isDepartmentRequired = true;
            return false;
        }
        else {
            this.isDepartmentRequired = false;
        }

        if (this.DropDownProductiveId == -1 || this.DropDownProductiveId == null) {
            this.isProductiveTypeRequired = true;
            return false;
        }
        else {
            this.isProductiveTypeRequired = false;
        }

        if (this.DepartmentShiftId == -1 || this.DepartmentShiftId == null) {
            this.isShiftCodeRequired = true;
            showShiftCodeRequiredError = true;
        }
        else {
            this.isShiftCodeRequired = false;
            showShiftCodeRequiredError = false;
        }

        if (this.SkillLevelID == -1 || this.SkillLevelID == null) {
            this.isSkillRequired = true;
            return false;
        }
        else {
            this.isSkillRequired = false;
        }

        // Disable changing skill if assignment cancelled.
        if (this.IsCancelled) {
            this.isSkillDisabled = true;
            this.showCancelOptions = true;
            this.showUnscheduledOptions = true;
        }

        //Note required on floating
        if (this.floatChangedByUser == 1) {
            this.isNoteRequired = true;
        }

        // Allow set change if productive shift and employee has capability.
        if (isChargeable && (this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE)) {
            if (this.isChargeDisabled) {
                this.Charge = true;
                this.isChargeDisabled = false;
            }
        }
        else {
            if (!this.isChargeDisabled) {
                this.isChargeDisabled = true;
                this.Charge = false;
            }
        }

        // Correct end time if set before start time
        if (parsedStartTime.isValid() && parsedEndTime.isValid()) {

            if (parsedEndTime.isBefore(parsedStartTime)) {
                parsedEndTime.add(1, 'days');
            }

            this.StartTime = parsedStartTime.toDate();
            this.EndTime = parsedEndTime.toDate();
        }

        // Show duration fields for non-productive shifts if selected.
        if (this.DropDownProductiveId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            this.isStandardShift = false;
            this.showEndTime = false;
            this.showNonProductiveTime = true;

            var durationHours = this.DurationHours;
            var durationMinutes = this.DurationMinutes;

            if (durationHours == null) {
                this.isDurationHoursRequired = true;
                return false;
            }
            else {
                this.isDurationHoursRequired = false;
            }

            if (durationMinutes == null) {
                this.isDurationMinutesRequired = true;
                return false;
            }
            else {
                this.isDurationMinutesRequired = false;
            }

            if ((durationHours === 0 && durationMinutes === 0) || durationHours === 0 && durationMinutes < 15) {
                this.isDurationValid = false;
                isHoursMinutesInvalid = true;
            }
            else {
                isHoursMinutesInvalid = false;
            }
        }
        else {
            this.isStandardShift = true;
            this.showEndTime = true;
            this.showNonProductiveTime = false;
            this.DurationHours = 0;
            this.DurationMinutes = 0;
        }

        if (this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED && !this.isCreate && !this.isSplit) {
            this.isCancelled = true;
        }
        else {
            this.isCancelled = false;
            this.isMoveDisabled = false;
        }

        // Do extra checks if control is in split mode.
        if (this.isSplit) {

            // Disable date and start time selection when in split mode.
            this.isStandardShift = true;

            // Disable duration selection when in split mode.
            if (this.DropDownProductiveId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
                this.isDurationDisabled = true;

                // calculate duration based on split time.
                var calulatedHours = parsedEndTime.diff(parsedStartTime, 'hours');
                var calculatedMinutes = parsedEndTime.diff(parsedStartTime, 'minutes');

                if (calulatedHours > 0 && calculatedMinutes > 0) {
                    calculatedMinutes = calculatedMinutes - (calulatedHours * 60);
                }

                this.DurationHours = calulatedHours;
                this.DurationMinutes = calculatedMinutes;
            }
            else {
                this.isDurationDisabled = false;
                this.DurationHours = 0;
                this.DurationMinutes = 0;
            }
         }

        if (this.showCancelOptions && this.CancelReasonId === -1) {
            //This prevents the users from going to the next screen
            this.isCancelReasonsRequired = true;
            var errorData = {
                msg: "Please select a cancel reason.",
                flag: "error"
            }
            this.emitter.emit('showAlertPopup', errorData);
            return false;
        }
        else {
            this.isCancelReasonsRequired = false;
        }

        //Show Duration Hours and Minutes invalid error
        if (isHoursMinutesInvalid) {
            this.isDurationHoursInvalid = true;
            this.isDurationMinutesInvalid = true;
            var errorData = {
                msg: "Duration must be greater at least 15 minutes.",
                flag: "error"
            }
            this.emitter.emit('showAlertPopup', errorData);
            return false;
        }
        else {
            this.isDurationHoursInvalid = false;
            this.isDurationMinutesInvalid = false;
            isHoursMinutesInvalid = false;
        }

        // Set note is required for Cancellation Reason - Other
        if (this.showCancelOptions && (this.CancelReasonId === 0 || this.CancelReasonId === null)) {
            this.isNoteRequired = true;
        }

        // Allow ??? to change Start and End Time
        if (this.ShiftCode === '???' ) {
            this.isStandardShift = false;

            if(this.isStandardShift == false && (this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
            {
                this.MinuteInterval = 15;
            }
            else{
                this.MinuteInterval = 1;
            }
        }

        // try to find the selected shift.
        if (this.DepartmentShiftId !== null) {
            shift = this.findShiftById(this.shiftCodes, this.DepartmentShiftId);
        }

        // Could not find the selected shift, use the non-standard (???).
        if ((shift === undefined || shift === null)) {
            shift = this.findShiftById(this.shiftCodes, 0);

            // Show time selection for non-standard shifts (???).
            if (shift !== undefined && shift.Code === '???' && this.DepartmentShiftId == 0 && !this.isSplit) {
                this.isStandardShift = false;
                if(this.isStandardShift == false && (this.DropDownProductiveId === this.SHIFT_TYPE_PRODUCTIVE || this.DropDownProductiveId === this.SHIFT_TYPE_OTHER_PRODUCTIVE))
                {
                    this.MinuteInterval = 15;
                }
                else{
                    this.MinuteInterval = 1;
                }
            }
        }

        // Display Alert to let users know that cancelling original assignment will be cancelled.
        if (this.assignmentEditInput != null && this.DropDownProductiveId !== null && this.assignmentEditInput.isCancelled === false) {
            if (this.isProductiveChanged(this.assignmentEditInput.productiveId, this.DropDownProductiveId)) {
                var productiveCodes = JSON.parse(JSON.stringify(this.productiveCodes));

                //@ts-ignore
                var originalShiftType: any = jslinq(productiveCodes)
                    .where((data: any) => {
                        if (data.fsProductiveID === this.assignmentEditInput.productiveId) {
                            return data;
                        }
                    })
                    .toList();

                //@ts-ignore
                var newShiftType: any = jslinq(productiveCodes)
                    .where((data: any) => {
                        if (data.fsProductiveID === this.DropDownProductiveId) {
                            return data;
                        }
                    })
                    .toList();

                this.showCancelOptions = true;
                this.showUnscheduledOptions = true;

                // If original assignment is Non-Productive being changed to Productive, Other-Productive, Call, hide Unschedule Absence Selection
                if (this.assignmentEditInput.productiveId === this.SHIFT_TYPE_NON_PRODUCTIVE) {
                    this.showUnscheduledOptions = false;
                }

                // If original assignment is Non-Productive being changed to cancelled, show Unschedule Absence Selection
                if (this.assignmentEditInput.productiveId === this.SHIFT_TYPE_NON_PRODUCTIVE &&
                    this.DropDownProductiveId === this.SHIFT_TYPE_CANCELLED) {
                    this.showUnscheduledOptions = true;
                }

                this.isProductiveChange = true;
                this.showStatus = true;

                // Have to figure out a way to display two alerts in the same control. This is just in case the duration warning is being display.
                if (this.isDurationValid) {
                    var errorData = {
                        msg: 'Changing Shift Type from ' + originalShiftType[0].Description +
                            ' to ' +
                            newShiftType[0].Description +
                            ' will result in the original assignment being cancelled and a new assignment being created.',
                        flag: "warning"
                    }
                    this.emitter.emit('showAlertPopup', errorData);
                }
            }
        }

        if (this.SkillLevelID > 0 && this.departmentSkills.length > 0 && this.departmentSkills.indexOf(this.SkillLevelID) == -1) {
            this.invalidSkill = true;
            return false;
        }
        else {
            this.invalidSkill = false;
        }

        if (this.isNoteRequired && (this.Note == null || this.Note.trim() == "")) {
            this.isNoteTxtRequired = true;
            return false;
        }
        else {
            this.isNoteTxtRequired = false;
        }

        if (showShiftCodeRequiredError) {
            return false;
        }
        return true;
    }

    isProductiveChanged(originalProductiveType: any, newProductiveType: any) {

        var isProductiveChange = false;

        if (originalProductiveType === this.SHIFT_TYPE_NON_PRODUCTIVE
            && newProductiveType !== this.SHIFT_TYPE_NON_PRODUCTIVE) {
            isProductiveChange = true;
        }

        if (originalProductiveType !== this.SHIFT_TYPE_NON_PRODUCTIVE
            && newProductiveType === this.SHIFT_TYPE_NON_PRODUCTIVE) {
            isProductiveChange = true;
        }

        return isProductiveChange;
    }

    modifiedAssignmentData() {
        var modifiedAssignmentData: any = {
            assignId: this.AssignId,
            staffId: this.StaffId,
            cancelComment: this.CancelComment,
            cancelReason: this.CancelReason,
            cancelReasonId: this.CancelReasonId,
            overtimeShift: this.OvertimeShift,
            unscheduledAbsence: this.UnscheduledAbsence,
            unscheduledAbsenceOption: this.UnscheduledAbsenceOptionId,
            departmentId: this.DepartmentId,
            firstName: this.FirstName,
            lastName: this.LastName,
            hireDate: this.HireDate,
            departmentCode: this.DepartmentCode,
            departmentName: this.DepartmentName,
            skillLevelId: this.SkillLevelID,
            skillLevelDescription: this.SkillLevelDescription,
            date: this.ShiftDate,
            charge: this.Charge == undefined ? false : this.Charge,
            profileCharge: this.ProfileCharge,
            needStatusId: this.NeedStatusId,
            origDepartmentCode: this.OrigDepartmentCode,
            origDepartmentId: this.OrigDepartmentId,
            origDepartmentName: this.OrigDepartmentName,
            departmentShiftId: this.DepartmentShiftId,
            shiftCode: this.ShiftCode,
            startTime: this.StartTime,
            endTime: this.EndTime,
            actualEndTime: this.ActualEndTime,
            actualStartTime: this.ActualStartTime,
            productiveId: this.ProductiveId,
            productiveDescription: this.ProductiveDescription,
            credentialTrackingStatusId: this.CredentialTrackingStatusId,
            employeeType: this.EmployeeType,
            hours: this.Hours,
            hoursWorked: this.assignmentEditInput.HoursWorked,
            fTE: this.FTE,
            isCancelled: this.IsCancelled == undefined ? false : this.IsCancelled,
            isFloat: Boolean(this.IsFloat == undefined ? false : this.IsFloat),
            defaultIsFloat: this.DefaultIsFloat,
            isOvertime: this.IsOvertime,
            staffShift: this.StaffShift,
            day: this.Day,
            credentialCode: this.CredentialCode,
            credentialNumber: this.CredentialNumber,
            credentialRenewalDate: this.CredentialRenewalDate,
            skillSort: this.SkillSort,
            countTypeId: this.CountTypeId,
            seniorityDate: this.SeniorityDate,
            clusterId: this.ClusterId,
            clusterName: this.ClusterName,
            name: this.EmployeeName,
            facilityId: this.FacilityId,
            facilityName: this.FacilityName,
            overTimeOccurence: this.OverTimeOccurence,
            assignmentDateCreated: this.AssignmentDateCreated,
            assignmentDateChanged: this.AssignmentDateChanged,
            lastCanceledDate: this.assignmentEditInput.LastCanceledDate,
            lastFloatedDate: this.assignmentEditInput.LastFloatedDate,
            departmentSummaryId: this.assignmentEditInput.DepartmentSummaryId,
            hasNotes: true,
            durationHours: this.DurationHours,
            durationMinutes: this.DurationMinutes,
            needId: this.assignmentEditInput.NeedId,
            countType: this.assignmentEditInput.CountType,
            secondarySkills: this.assignmentEditInput.SecondarySkills,
            associationType: this.AssociationType,
            phoneNumber: this.assignmentEditInput.PhoneNumber,
            staffOrigDepartmentId: this.StaffOrigDepartmentId,
            competencies: this.assignmentEditInput.Competencies,
            rnExperience: this.assignmentEditInput.RNExperience,
            rnExperienceFeatureEnabled: this.assignmentEditInput.RNExperienceFeatureEnabled,
            staffVolume: this.assignmentEditInput.StaffVolume,
            allSkills: this.assignmentEditInput.AllSkills,
            note: this.Note,
            actionableDepartment: this.assignmentEditInput.actionableDepartment,
            config: this.assignmentEditInput.config,
            facilityConfig: this.assignmentEditInput.facilityConfig,
            dropDownProductiveId: this.DropDownProductiveId,
            serviceLineId: this.ServiceLineId,
            isFloatVisible: this.isFloatVisible,
            floatChangedByUser: this.floatChangedByUser,
            isSplit: this.isSplit,
            isMoveDisabled: this.isMoveDisabled,
            showCancelOptions: this.showCancelOptions,
            showUnscheduledOptions: this.showUnscheduledOptions,
            unscheduledAbsenceText: this.UnscheduledAbsenceText
        }

        if (this.containerType.toLowerCase() == "editAssignment".toLowerCase()) {
            this.emitter.emit('updateModifiedAssignmentData', modifiedAssignmentData);
        }
        else if (this.containerType.toLowerCase() == "splitAAssignment".toLowerCase()) {
            this.emitter.emit('updateModifiedSplitAAssignmentData', modifiedAssignmentData);
        }
        else if (this.containerType.toLowerCase() == "splitBAssignment".toLowerCase()) {
            this.emitter.emit('updateModifiedSplitBAssignmentData', modifiedAssignmentData);
        }
        else if (this.containerType.toLowerCase() == "createAssignment".toLowerCase()) {
            this.emitter.emit('updateModifiedCreateAssignmentData', modifiedAssignmentData);
        }
    }

    sendValidationFlag(validationStatus: boolean) {
        var statusData: any = {
            eventType: this.containerType,
            flag: validationStatus
        }
        this.emitter.emit('updateValidationFlag', statusData);
    }
    OnChangeValueDateTime(ev:any, Duration:any) {
        if(Duration === "DurationHours"){
            this.DurationHours = ev.target.value == "" || ev.target.value === null || ev.target.value == "0" ?  0 : ev.target.value;
        }
        else{
            this.DurationMinutes = ev.target.value == "" || ev.target.value === null || ev.target.value == "0"?  0 : ev.target.value;
        }
        this.dateTimeOnChange();
    }
}
</script>
<style scoped>
#Actionable .content {
    height: calc(100vh - 240px) !important;
}

.assignment {
    position: relative;
    background-color: #fff;
    z-index: 1050;
    height: calc(100vh - 285px) !important;
}

.assignment-notes-container {
    overflow: auto;
    max-height: 300px;
}

.invalid-msg {
    border-color: #ffb400;
    background-color: #ffb400;
    color: #000;
}

    .neu-input__select-icon {
        display: block;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
        top: 2.125rem;
        right: 20px;
        background-color: transparent;
        transition: 0.5s ease-in-out;
        pointer-events: none;
    }

.readOnly {
    background-color: #e9ecef !important;
    opacity: 1 !important;
}

.requiredControl {
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 2.1875rem;
    right: 37px;
    z-index: 2147483647;
}

.requiredNoteControl {
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 2.5rem;
    right: 37px;
    z-index: 2147483647;
}

.requiredCancelRsnControl {
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 2.1875rem;
    right: 37px;
    z-index: 2147483647;
}

.requiredDurationControl {
    color: #DF2500;
    display: inline-block;
    position: absolute;
    top: 0.4375rem;
    right: 37px;
    z-index: 2147483647;
}

.requiredTxt {
    color: #DF2500 !important;
    display: block;
}

.hasError {
    border: 2px solid #ce3637 !important;
}

.text-end {
    left: 1090px !important;
    position: relative;
}
</style>
